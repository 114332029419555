<template>
    <navbar btn-background="bg-gradient-success" />

    <main class="mt-0 main-content">
        <div class="page-header align-items-start min-vh-50 m-3 border-radius-lg" 
         :style="{
           backgroundImage: 'url(' + require('@/assets/img/adam_bot.jpg') + ')',
           backgroundSize: 'cover',
           backgroundPosition: 'center',
           backgroundRepeat: 'no-repeat'
         }">
            <span class="mask bg-gradient-dark opacity-6"></span>
        </div>
        <section>
            <div class="container mb-4">
                <div class="row mt-lg-n12 mt-md-n12 mt-n12 justify-content-center">
                    <div class="col-xl-4 col-lg-5 col-md-7 mx-auto">
                        <div class="card mt-8">
                            <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                <div
                                    class="bg-gradient-success shadow-success border-radius-lg py-3 pe-1 text-center py-4">
                                    <h3 class="font-weight-bolder text-white">Join us today
                                        </h3>
                                    <p style="color: white;">Enter your email and password to register</p>
                                </div>
                            </div>
                            <div class="py-4 card-body">
                                <Form role="form" :validation-schema="schema" @submit="handleSignup">
                                    <div class="mb-3">
                                        <material-input-field id="name" v-model:value="user.name" type="text"
                                            label="Name" placeholder="" name="name" variant="static" />
                                    </div>
                                    <div class="mb-3">
                                        <material-input-field id="email" v-model:value="user.email" type="email"
                                            label="Email" placeholder="john@email.com" name="email" variant="static" />
                                    </div>
                                    
                                    <div class="mb-3">
                                        <material-input-field id="password" v-model:value="user.password" type="password"
                                            label="Password"  name="password" variant="static" />
                                    </div>
                                    <div class="mb-3">
                                        <material-input-field id="confirmPassword" v-model:value="user.confirmPassword" type="password"
                                            label="Confirm Password"  name="confirmPassword" variant="static" />
                                    </div>
                                    <div class="text-center">
                                        <material-button class="mt-4" variant="gradient" color="success"
                                            full-width>Register</material-button>
                                    </div>
                                    <p class="mt-4 text-sm text-center">
                                    Already have an account?
                                    <router-link :to="{ name: 'Login' }"
                                        class="text-success text-gradient font-weight-bold">Sign
                                        in</router-link>
                                </p>
                                
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
import MaterialInputField from "@/components/MaterialInputField.vue";
import MaterialButton from "@/components/MaterialButton.vue";
import * as Yup from 'yup'
const body = document.getElementsByTagName("body")[0];
import { mapMutations } from "vuex";

import { Form } from "vee-validate";
import showSwal from "@/mixins/showSwal";
import backgroundImage from '@/assets/img/adam_bot.jpg';
export default {
    name: "Signup",
    components: {
        Navbar,
        AppFooter,
        MaterialInputField,
        MaterialButton,
        Form
    },
    data() {
        return {
            imageUrl: backgroundImage,
            user: [],
            termsChecked: true,
            schema: Yup.object().shape({
                name: Yup.string().required("Name is a required input"),
                email: Yup.string().email("Email has to be a valid email address").required("Email is a required input"),
                password: Yup.string().required("Password is a required input").min(8, "Password must have at least 8 characters"),
                confirmPassword: Yup.string().required("Confirm password is a required input").oneOf([Yup.ref('password')], 'Your passwords do not match.'),
                
            }),
        }
    },
    beforeMount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.remove("bg-gray-100");
    },
    beforeUnmount() {
        this.toggleEveryDisplay();
        this.toggleHideConfig();
        body.classList.add("bg-gray-100");
    },
    methods: {
        ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
        async handleSignup() {
    try {
        let response = await this.$store.dispatch('auth/register', this.user);
        console.log('Registration Response:', response);

        showSwal.methods.showSwal({
            type: "success",
            message: "Successfully registered!Please Verify your email address to login",
            width: 500,
            timer: 160000, // 1 minute
            showConfirmButton: true
        });

        this.$router.push({ name: 'Login' })   
    } catch (error) {
        console.error('Signup error:', error); // Log the error
        showSwal.methods.showSwal({
            type: "error",
            message: "Email Already Exists!",
            width: 500
        });
    }
}

        
  }
    
};
</script>
